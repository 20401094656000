import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { Search } from "../../../images";

const CssTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    backgroundColor: "#F4F3F2",
    borderRadius: "50px",
  },
  "& .MuiInputLabel-filled.MuiInputLabel-marginDense": {
    fontSize: "12px",
  },
  "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after": {
    borderBottom: "none",
  },
  "& input": {
    paddingLeft: "20px",
  },
  "& .MuiFormLabel-root": {
    paddingLeft: "10px",
  },
});

function SearchField({
  searchText,
  onSearchTextChanged,
  placeholder,
  label,
  ...containerProps
}) {
  return (
    <Box width="360px" {...containerProps}>
      <CssTextField
        id="case-filter-search"
        label={label}
        inputStyle={{ backgroundColor: "red" }}
        placeholder={placeholder}
        size="small"
        variant="filled"
        fullWidth
        value={searchText}
        onChange={e => onSearchTextChanged(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchText !== "" && (
                <IconButton
                  size="small"
                  onClick={() => onSearchTextChanged("")}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

SearchField.propTypes = {
  searchText: PropTypes.string,
  onSearchTextChanged: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

SearchField.defaultProps = {
  searchText: "",
  placeholder: "",
  label: "",
};

export default SearchField;
